import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core";
import Layout from "../components/layout"
import SEO from "../components/seo"
import Helmet from "react-helmet";

import SignInForm, {
    SignInGoogle,
    SignInSaml,
} from '../components/SignIn';


const SignInPage = () => (
    <React.Fragment>
        <SignInForm />
        <SignInGoogle />
        <SignInSaml />
    </React.Fragment>
);
  
const LoginPage = () => (
  <Layout>
    <SEO title="Compass Minerals SaltSmart" />
      <Helmet defer={true}>
        <body className="login" />
      </Helmet>
      <header className="mainHead flexContainer homeHead  faCenter fjCenter">      
        <div className="fCol12 menuCtrl">
          <div className="headerTitle">Home</div>
        
        </div>
      </header>
      <section id="home-content" className="flexContainer secondaryTab">
        <div className="fCol12 maxTablet">
          <div className="flexContainer ">
            <div css={css`text-align:center`}>
                <SignInPage />
            </div>
            <br />
            <div css={css`text-align:center`}>
                <p css={css`text-transform:uppercase;`}>
                    Don't Have a Login?<br />
                    <Link to="home" css={css`color:#fff`}>Continue as Guest</Link>
                </p>                
            </div>
          </div>
          
        </div>
        <div className="compassLogo"/>
      </section>    
  </Layout>
);



export default LoginPage


